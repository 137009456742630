export enum PermissionTarget {
	// Static data
	STATIC_BRAND = "static.brand",
	STATIC_COUNTRY = "static.country",
	STATIC_LOCALE = "static.locale",
	STATIC_LOCALIZATION = "static.localization",

	// Global entities
	COMPANY = "company",
	MOVIE = "movie",
	PERSON = "person",
	SERIES = "series",
	THEATER = "theater",
	FESTIVAL = "festival",

	// Entity global permissions
	ENTITY_DELETION = "entity.delete",

	// Entity overrides
	ENTITY_BRAND_DATA = "entity.brand_data",
	ENTITY_LOCALE_DATA = "entity.locale_data",
	ENTITY_SEO_DATA = "entity.seo_data",
	ENTITY_RELEASES = "entity.releases",

	// Events
	ENTITY_CHANGE_EVENT = "entity.change_event",

	// Branded entities
	BRAND_AUTHOR = "brand.author",
	BRAND_CAROUSEL = "brand.carousel",
	BRAND_EDITORIAL_REVIEW = "brand.editorial_review",
	BRAND_SPECIAL_OPERATION = "brand.special_operation",
	BRAND_NEWS = "brand.news",
	BRAND_PRESS_REVIEW = "brand.press_review",
	BRAND_TAG = "brand.tag",
	BRAND_TRIVIA = "brand.trivia",

	// Media
	MEDIA_IMAGE = "media.image",
	MEDIA_VIDEO = "media.video",

	// Misc
	BOXOFFICE = "boxoffice",
	DUPLICATE = "duplicate",
	PRODUCT_MATCHING = "product.matching",

	// Social
	SOCIAL_USER = "social.user",
	SOCIAL_USER_REVIEW = "social.user_review",

	// Khronos
	KHRONOS_IMPORT_CRAWLER = "khronos.import_crawler",
	KHRONOS_IMPORT_CONFIGURATION = "khronos.import_configuration",
	KHRONOS_IMPORT_RUN = "khronos.import_run",
	KHRONOS_SHOWTIME = "khronos.showtime",

	// Showtimes
	SHOWTIME_CLOCK = "showtime.clock",
	SHOWTIME_MATCHING = "showtime.matching",

	// Graph API & Proxy
	GRAPH_API_APPLICATION = "graph_api.application",
	GRAPH_API_TOKEN = "graph_api.token",
	GRAPH_API_PROXY = "graph_api.proxy",

	// Jump platform
	JUMP_PLATFORM_GENERATE_LINK = "jump_platform.generate_link",

	// Security
	SECURITY_PRESET = "security.preset",
	SECURITY_USER = "security.user",

	// Movie
	MOVIE_BRANDED_CONTENT = "movie.branded_content",
	MOVIE_CLUB300 = "movie.club_300",
	MOVIE_CONNECTION = "movie.connection",
	MOVIE_LINK_TAGS = "movie.link_tags",
	MOVIE_SHOWTIMES_EMBARGOS = "movie.showtimes_embargos",
	MOVIE_BRANDED_DATA_COPY_TO_LOCALE_DATA = "movie.branded_data.copy_to_locale_data",

	// Series
	SERIES_LINK_TAGS = "series.link_tags",
	SERIES_TMDB_IMPORT = "series.tmdb_import",
	SERIES_SEASON_DELETE = "series_season.delete",
	SERIES_EPISODE_DELETE = "series_episode.delete",

	// News
	NEWS_LINK_TAGS = "news.link_tags",

	// Widget
	WIDGET = "secure.widget",

	// Ratings
	CONFIGURATION_RATINGS = "configuration.ratings",
}
