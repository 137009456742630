import clsx from "clsx"
import type { ChangeEvent, InputHTMLAttributes, ReactNode } from "react"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { ClearIcon } from "../icons/clear-icon"
import { isEmpty } from "../utils/check"
import { IconButton } from "./button"

export function inputStyle({ readOnly, disabled, warning, invalid }: InputProps) {
	return clsx(
		"py-1 text-sm text-gray-600 transition-colors",
		"border-0 border-b border-solid border-gray-400",
		"focus:outline-none focus:border-blue-700",
		{
			"pointer-events-none": readOnly,
			"cursor-not-allowed": disabled,
			"ring-2 ring-offset-2 ring-opacity-50 rounded border-none": invalid || warning,
			"ring-red-400": invalid,
			"ring-yellow-600": warning,
		},
	)
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	invalid?: boolean
	warning?: boolean
	isClearable?: boolean
	Icon?: ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
	const {
		className,
		disabled = false,
		invalid = false,
		warning = false,
		readOnly = false,
		Icon = null,
		type = "text",
		isClearable = false,
		...rest
	} = props
	const innerRef = useRef<HTMLInputElement | null>(null)
	useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

	const input = (
		<input
			{...rest}
			ref={innerRef}
			type={type}
			className={clsx(
				className,
				{ "pl-6": Icon },
				inputStyle({ invalid, warning, readOnly, disabled }),
				// {
				// 	"w-full": isClearable,
				// },
				"w-full",
			)}
			disabled={disabled}
			readOnly={readOnly}
		/>
	)

	const inputElt = Icon ? (
		<div className="relative">
			{Icon && <span className="absolute">{Icon}</span>}
			{input}
		</div>
	) : (
		input
	)

	const hasValue = !(isEmpty(props.value) && isEmpty(props.defaultValue))

	return isClearable ? (
		<div className="flex gap-2">
			<div className="flex-grow">{inputElt}</div>
			<IconButton
				className="animate-button-appear"
				color="grayed"
				disabled={!hasValue}
				onClick={() => {
					props.onChange?.({
						target: { value: "" },
					} as ChangeEvent<HTMLInputElement>)
					innerRef?.current?.focus()
				}}
			>
				<ClearIcon />
			</IconButton>
		</div>
	) : (
		inputElt
	)
})
