import { useIsGranted, useIsGranted_LEGACY } from "@utils/auth"
import { useCallback } from "react"
import { PermissionTarget } from "../config/permissionTargets"

export enum SecurityMode {
	LEGACY,
	NEW,
}

export function useSecurity() {
	const isGrantedNew = useIsGranted()
	const isGranted = useIsGranted_LEGACY()

	const canDeleteEntities = useCallback(() => isGrantedNew(PermissionTarget.ENTITY_DELETION), [isGrantedNew])
	const canDeleteSeries = useCallback(
		() => canDeleteEntities() && isGrantedNew(PermissionTarget.SERIES),
		[isGrantedNew, canDeleteEntities],
	)

	return {
		isGrantedNew,
		isGranted,
		canDeleteEntities,
		canDeleteMovies: () => canDeleteEntities() && isGrantedNew(PermissionTarget.MOVIE),
		canDeletePersons: () => canDeleteEntities() && isGrantedNew(PermissionTarget.PERSON),
		canDeleteSeries,
		canDeleteSeriesSeason: () => canDeleteSeries() || isGrantedNew(PermissionTarget.SERIES_SEASON_DELETE),
		canDeleteSeriesEpidodes: () => canDeleteSeries() || isGrantedNew(PermissionTarget.SERIES_EPISODE_DELETE),
	}
}
